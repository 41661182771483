<template>
    <label class="dropzone" :for="inputId">
        <span class="d-block">{{title}}</span>
        <input type="file" :id="inputId" :name="name" ref="input" @change="onChange" :multiple="multiple">
        <div class="dropzone-images" v-if="files.length > 0" :class="{'is-invalid': error !== undefined, 'dropzone-images--single': !multiple}">
            <div class="dropzone-image-wrapper" v-for="(image,index) in files">
                <img class="dropzone-image" :src="getFileDataForImage(index)">
                <p class="dropzone-file-name mb-0">{{image.name}}</p>
            </div>
        </div>
        <div class="dropzone-no-images" :class="{'is-invalid': error !== undefined}" v-else>
            <span>Drop Files Here To Upload</span>
        </div>
        <div class="invalid-feedback" v-if="error !== undefined">{{error}}</div>
    </label>
</template>

<script>
    export default {
        name: "Dropzone",

        props: {
            error: {
                type: String
            },
            multiple: {
                type: Boolean,
                default: false
            },
            amount: {
                type: Number,
                default: -1,
            },
            name: {
                type: String
            },
            title: {
                type: String
            },
            value: {},
            dropzoneId: {} //setting the default here was causing duplicate ids for some reason
        },

        data() {
            return {
                files: {},
                fileData: {},
                inputId: '',
            }
        },

        mounted() {
            this.inputId = this.dropzoneId;
            if(this.dropzoneId == undefined){
                this.inputId = 'dropzone_' + Math.random().toString(36).substr(2, 9);
            }

            this.$el.addEventListener('dragover', (e) => {
               e.preventDefault();
            });

            this.$el.addEventListener('drop', (e) => {
                e.preventDefault();
                this.$refs.input.files = e.dataTransfer.files;
                this.files = Object.values(e.dataTransfer.files);
                this.$emit('input', e.dataTransfer.files);
                this.getFileData();
            });
        },

        methods: {
            onChange(e) {
                console.log(Object.values(e.target.files), this.files, this);
                this.files = Object.values(e.target.files);
                this.$emit('input', e.target.files);
                this.getFileData();
            },

            getFileData() {
                  this.files.forEach((file, index) => {
                      this.getImageSrc(file).then((res) => {
                          Vue.set(this.fileData, index, res);
                      })
                  });
            },

            getImageSrc(image){
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();

                    reader.onload = function (e) {
                        resolve(e.target.result);
                    };

                    reader.readAsDataURL(image);
                });
            }
        },

        computed: {
            getFileDataForImage() {
                return (image) => {
                    return this.fileData[image] != undefined ? this.fileData[image] : '';
                }
            }
        },

        watch: {
            value(newValue) {
                if(newValue == null){
                    this.$refs.input.value = '';
                    this.files = {};
                    this.fileData = {};
                }
            }
        }
    }
</script>
