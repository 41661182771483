
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import ExampleComponent from './components/ExampleComponent.vue';
Vue.component('example-component', ExampleComponent);

import SizeBox from './components/SizeBox.vue';
Vue.component('size-box', SizeBox);

import FranchiseSizeBox from './components/FranchiseSizeBox.vue';
Vue.component('franchise-size-box', FranchiseSizeBox);

import EventForm from './components/EventForm.vue';
Vue.component('event-form', EventForm);

import ChartTabs from './components/ChartTabs.vue';
Vue.component('chart-tabs', ChartTabs);

import AdminDashboard from './components/AdminDashboard.vue';
Vue.component('admin-dashboard', AdminDashboard);

import FranchiseLeaderboard from './components/FranchiseLeaderboard.vue';
Vue.component('admin-franchise-leaderboard', FranchiseLeaderboard);

import NotificationCreate from './components/NotificationCreate.vue';
Vue.component('notification-create', NotificationCreate);

import FranchiseNotificationCreate from "./components/FranchiseNotificationCreate";
Vue.component('franchise-notification-create', FranchiseNotificationCreate);

//Phase 2
import SingleDayReportsPage from './components/Phase2/pages/SingleDayReportsPage.vue';
Vue.component('single-day-reports-page', SingleDayReportsPage);

import DailyReportsPage from './components/Phase2/pages/DailyReportsPage.vue';
Vue.component('daily-reports-page', DailyReportsPage);

import DailyReportGraphsPage from './components/Phase2/pages/DailyReportGraphsPage.vue';
Vue.component('daily-report-graphs-page', DailyReportGraphsPage);

import StopStatsPage from './components/Phase2/pages/StopStatsPage.vue';
Vue.component('stop-stats-page', StopStatsPage);

import MultiVanPage from './components/Phase2/pages/MultiVanPage.vue';
Vue.component('multi-van-page', MultiVanPage);

import ProductStatsPage from './components/Phase2/pages/ProductStatsPage.vue';
Vue.component('product-stats-page', ProductStatsPage);

import ProductWastageStatsPage from './components/Phase2/pages/ProductWastageStatsPage.vue';
Vue.component('product-wastage-stats-page', ProductWastageStatsPage);

import CustomerStatsPage from './components/Phase2/pages/CustomerStatsPage.vue';
Vue.component('customer-stats-page', CustomerStatsPage);

import EventReportsPage from './components/Phase2/pages/EventReportsPage.vue';
Vue.component('event-reports-page', EventReportsPage);

import SingleEventReportPage from './components/Phase2/pages/SingleEventReportPage.vue';
Vue.component('single-event-report-page', SingleEventReportPage);


import StopUpdateRequiredModal from './components/StopUpdateRequiredModal.vue';
Vue.component('stop-update-required-modal', StopUpdateRequiredModal);

import StopTrafficLightForm from './components/StopTrafficLightForm.vue';
Vue.component('stop-traffic-light-form', StopTrafficLightForm);

Vue.component('notification-set', require('./components/NotificationSet').default);
Vue.component('use-notification-set', require('./components/UseNotificationSet').default);

Vue.component('flatpickr', require('./components/generic/Flatpickr').default);
Vue.component('dropzone', require('./components/generic/Dropzone').default);
Vue.component('modal', require('./components/generic/Modal').default);
Vue.component('edit-notification-group', require('./components/EditNotificationGroup').default);

Vue.component('preorder-categories', require('./components/PreorderCategories').default);
Vue.component('orderable-table', require('./components/OrderableTable').default);

if(typeof window.initialiseReportingV2 === 'function'){
    window.initialiseReportingV2();
}

