<template>
    <div>
        <div class="d-block invalid-feedback" v-if="errors.categories !== undefined">Atleast 1 category is required</div>

        <draggable tag="ul" class="list-group top-padding mb-3" v-model="currentCategories" :options="{group: {name: 'categories'}}" handle=".draggable">
            <transition-group>
                <li class="list-group-item" v-for="(category, categoryIndex) in currentCategories" :key="`category-list--${categoryIndex}`">
                    <div class="d-flex align-items-start">
                        <div class="draggable p-2 pr-5">
                            <i class="fas fa-lg fa-arrows-alt-v"></i>
                        </div>

                        <div class="flex-grow-1">
                            <div class="input-group">
                                <input class="form-control" :class="{'is-invalid': errors[`categories.${categoryIndex}.name`] !== undefined}" placeholder="Category Name" v-model="category.name">
                                <div class="input-group-append" v-if="!category.is_core">
                                    <button @click.prevent="deleteCategory(categoryIndex)" class="btn btn-danger">Delete Category</button>
                                </div>
                                <div class="invalid-feedback small font-italic" v-if="errors[`categories.${categoryIndex}.name`] !== undefined">{{errors[`categories.${categoryIndex}.name`][0]}}</div>
                            </div>

                            <collapse class="mt-4 p-3 bg-light" :class="{'is-invalid': errors[`categories.${categoryIndex}.subcategories`] !== undefined}">
                                <div slot="title">
                                    <h4 slot="title">Subcategories</h4>
                                    <div class="d-block invalid-feedback" v-if="errors[`categories.${categoryIndex}.subcategories`] !== undefined">A category must contain atleast 1 subcategory</div>
                                </div>
                                <draggable tag="ul" class="list-group top-padding mb-3" v-model="category.subcategories" :options="{group: {name: `subcategories-${categoryIndex}`}}" :handle="`.subcategory-draggable-${categoryIndex}`">
                                
                                    <transition-group>
                                        <li class="list-group-item" v-for="(subcategory, subcategoryIndex) in category.subcategories" :key="`subcategory-list--${subcategoryIndex}`">
                                            <div class="d-flex align-items-start">
                                                <div class="p-2 pr-5" :class="`subcategory-draggable-${categoryIndex}`">
                                                    <i class="fas fa-lg fa-arrows-alt-v"></i>
                                                </div>

                                                <div class="input-group flex-grow-1">
                                                    <input class="form-control" :class="{'is-invalid': errors[`categories.${categoryIndex}.subcategories.${subcategoryIndex}.name`] !== undefined}" placeholder="Subcategory Name" v-model="subcategory.name">
                                                    <div class="input-group-append">
                                                        <button @click.prevent="deleteSubcategory(categoryIndex, subcategoryIndex)" class="btn btn-danger">Delete Subcategory</button>
                                                    </div>
                                                    <div class="invalid-feedback small font-italic" v-if="errors[`categories.${categoryIndex}.subcategories.${subcategoryIndex}.name`] !== undefined">{{errors[`categories.${categoryIndex}.subcategories.${subcategoryIndex}.name`][0]}}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </transition-group>
                                </draggable>

                                <button class="btn btn-primary w-100" @click.prevent="addSubcategory(categoryIndex)">Add Subcategory</button>
                            </collapse>
                        </div>
                    </div>
                </li>
            </transition-group>
        </draggable>

        <button class="btn btn-primary w-100" @click.prevent="addCategory">Add Category</button>
    </div>
</template>

<script>
    import draggable from 'vuedraggable/src/vuedraggable';
    import collapse from './generic/Collapse.vue';

    export default {

        components: {
            draggable,
            collapse
        },

  
        props: {
            categories: {
                type     : Array,
                required : false
            }
        },

        data() {
            return {
                currentCategories : [],
                errors            : {}
            };
        },

        beforeMount() {
            this.currentCategories = [...this.categories];
        },

        mounted(){
            let self = this;
            $('#rapos-form').submit(function(e){
                e.preventDefault();

                let data = {
                    ...$(this).serializeArray(),
                    categories: self.currentCategories
                };

                axios.patch($(this).attr('action'), data)
                    .then((response) => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        self.errors = error.response.data.errors;
                    });
            });
        },

        methods: {
            addCategory(){
                this.currentCategories.push({
                    name: '',
                    subcategories: []
                })
            },

            deleteCategory(categoryIndex){
                this.currentCategories.splice(categoryIndex, 1);
            },

            addSubcategory(categoryIndex){
                this.currentCategories[categoryIndex].subcategories.push({
                    name: ''
                });
            },

            deleteSubcategory(categoryIndex, subcategoryIndex){
                this.currentCategories[categoryIndex].subcategories.splice(subcategoryIndex, 1);
            }
        }
    }
</script>
