<template>
    <table class="table table-striped">
        <thead class="thead-dark">
            <tr>
                <slot name="header"></slot>
            </tr>
        </thead>
        <draggable tag="tbody" v-model="orderedOptions" handle=".draggable">
            <tr v-for="option in orderedOptions" :key="option.id">
                <slot :option="option" :edit-url="editUrl" :delete-url="deleteUrl" :order-changed="orderChanged" name="option"></slot>
            </tr>
        </draggable>
        <tfoot>
            <tr>
                <th colspan="3" align="end">
                    <button class="btn btn-primary" @click="save" :disabled="!orderChanged">Update Order</button>
                </th>
            </tr>
        </tfoot>
    </table>
</template>

<script>
import draggable from 'vuedraggable/src/vuedraggable';

export default {
    name: "OrderableTable",

    components: {
        draggable
    },

    props: {
        options: {
            type     : Array,
            required : true
        },

        saveUrl: {
            type: String,
            required: true
        },

        editUrl: {
            type: String,
            required: true
        },

        deleteUrl: {
            type: String,
            required: true
        }
    },

    data(){
        return {
            orderedOptions: []
        }
    },

    beforeMount() {
        this.orderedOptions = [...this.options];
    },

    methods: {
        save: function(){
            axios.post(this.saveUrl, {
                options: this.orderedOptions
            }).then(() => {
                window.location.reload();
            })
        }
    },

    computed: {
        orderChanged: function(){
            return !this.orderedOptions.every((value, index) => value === this.options[index])
        }
    }
}
</script>